<template>
  <en-drawer :model-value="modelValue" title="新增套餐" direction="btt" @close="$emit('update:model-value', false)" size="80%">
    <en-form :model="form.data" :loading="form.loading" class="grid grid-cols-4 gap-x-6">
      <en-form-item label="套餐编号">
        <en-input :model-value="form.data.mallGroupPurchase?.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="套餐名称">
        <en-input v-model="form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="套餐简介">
        <en-input v-model="form.data.intro"></en-input>
      </en-form-item>
      <en-form-item label="活动有效期">
        <en-date-picker
          :start="form.data.mallGroupPurchase?.startDate"
          :end="form.data.mallGroupPurchase?.endDate"
          type="daterange"
          @change="form.mallGroupPurchase.date.change"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="套餐成本">
        <en-input-number v-model="form.data.cost" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="套餐价格">
        <en-input-number v-model="form.data.sellPrice" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="赠送金额">
        <en-input-number v-model="form.data.freeAmount" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="支持卡券赠送">
        <select-maintain
          :model-value="form.data.mallGroupPurchase?.sendingCouponInstanceRedPackAllowed"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
          @change="form.mallGroupPurchase.sendingCouponInstanceRedPackAllowed.change"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="仅支持线下">
        <select-maintain
          :model-value="form.data.mallGroupPurchase?.offline"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
          @change="form.mallGroupPurchase.offline.change"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment"></en-input>
      </en-form-item>
    </en-form>

    <div class="p-5 flex flex-col gap-5">
      <div class="w-full flex items-center justify-between">
        <span class="text-lg font-weight-500">套餐卡券</span>
        <en-badge :value="form.data.mallComboCoupons?.length">
          <en-button type="primary" @click.stop="form.mallComboCoupons.add.click">添加卡券</en-button>
        </en-badge>
      </div>
    </div>

    <en-table :data="form.data.mallComboCoupons">
      <en-table-column label="验证码" prop="serialNo"></en-table-column>
      <en-table-column label="卡券名称">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <select-maintain
            v-model="row.mallCoupon"
            :ajax="{ action: 'GET /enocloud/mall/coupon', params: (params, value) => (params.payload = { name: value, statusCode: 'A' }) }"
            :props="{ label: 'name', value: '', disabled: (option: EnocloudMallDefinitions['MallCouponDto']) => Boolean(form.data.mallComboCoupons?.find(item => item.mallCoupon?.id === option.id)) }"
            value-key="id"
            class="w-full"
          ></select-maintain>
        </template>
      </en-table-column>
      <en-table-column label="卡券类型" prop="mallCoupon.type.message"></en-table-column>
      <en-table-column label="面值" prop="mallCoupon.faceValue"></en-table-column>
      <en-table-column label="满减金额" prop="mallCoupon.lowerLimitAmount"></en-table-column>
      <en-table-column label="折扣">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          {{ row.mallCoupon?.type?.discountMethod.code === 'P' ? row.mallCoupon.discountValue : 0 }}
        </template>
      </en-table-column>
      <en-table-column label="抵扣金额">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          {{ row.mallCoupon?.type?.discountMethod.code === 'F' ? row.mallCoupon.discountValue : 0 }}
        </template>
      </en-table-column>
      <en-table-column label="项目" prop="mallCoupon.maintenance.name"></en-table-column>
      <en-table-column label="配件" prop="mallCoupon.goods.name"></en-table-column>
      <en-table-column label="有效期类型" width="150">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <select-maintain
            v-model="row.expireType"
            :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['EXPTYPE']) }"
            :props="{ label: 'description', value: '' }"
            value-key="code"
            class="w-full"
          ></select-maintain>
        </template>
      </en-table-column>
      <en-table-column label="有效期" width="200">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <en-date-picker
            v-if="row.expireType?.code === 'F'"
            v-model:start="row.startDate"
            v-model:end="row.endDate"
            type="daterange"
          ></en-date-picker>
          <en-input-number v-if="row.expireType?.code === 'D'" v-model="row.validityDays" class="w-full"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="本次发放总量" prop="totalCount"></en-table-column>
      <en-table-column label="单次领取量" prop="count" width="150">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <en-input-number v-model="row.count" class="w-full"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="固定核销天数" prop="count" width="150">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <en-input-number v-model="row.periodDays" :min="0" :precision="0" :disabled="row.count && row.count > 1" class="w-full"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="固定核销次数" prop="count" width="150">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <en-input-number
            v-model="row.countPerInstance"
            :min="1"
            :precision="0"
            :disabled="row.count && row.count > 1"
            class="w-full"
          ></en-input-number>
        </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
const mallGroupPurchaseInit = <P extends Partial<EnocloudMallDefinitions['MallGroupPurchaseDto']>>(
  props?: P
): EnocloudMallDefinitions['MallGroupPurchaseDto'] => {
  return Object.assign({ mallCombos: [] }, props) as any
}

const mallComboCouponInit = <P extends Partial<EnocloudMallDefinitions['MallComboCouponDto']>>(
  props?: P
): EnocloudMallDefinitions['MallComboCouponDto'] => {
  return Object.assign({}, props) as any
}

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudMallDefinitions['MallComboDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler(visible) {
        if (visible && this.data?.id) {
          this.form.data.id = this.data.id
          await this.form.get()
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/combo/:mallComboId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/mall/group/purchase',
            validate: true,
            params(params) {
              params.payload = mallGroupPurchaseInit({ mallCombos: [this.form.data] })
            }
          }
        },
        children: {
          mallGroupPurchase: {
            date: {
              change(value: [string, string]) {
                this.form.data.mallGroupPurchase ??= mallGroupPurchaseInit()
                this.form.data.mallGroupPurchase.startDate = value[0]
                this.form.data.mallGroupPurchase.endDate = value[1]
              }
            },
            sendingCouponInstanceRedPackAllowed: {
              change(value: EnocloudCommonDefinitions['LookupDto']) {
                this.form.data.mallGroupPurchase ??= mallGroupPurchaseInit()
                this.form.data.mallGroupPurchase.sendingCouponInstanceRedPackAllowed = value
              }
            },
            offline: {
              change(value: EnocloudCommonDefinitions['LookupDto']) {
                this.form.data.mallGroupPurchase ??= mallGroupPurchaseInit()
                this.form.data.mallGroupPurchase.offline = value
              }
            }
          },
          mallComboCoupons: {
            add: {
              click() {
                this.form.data.mallComboCoupons ??= []
                this.form.data.mallComboCoupons?.unshift(mallComboCouponInit())
              }
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.form.submit()
              this.footer.cancel.click()
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
